import { useLazyQuery, useMutation } from "@apollo/client";
import { DSBreakpoints, DSPalette } from "@clickbank-ui/seller-design-system";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Grid, Link, Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";
import React, { useContext, useRef } from "react";
import { Trans, getI18n } from "react-i18next";

import { ApolloClient, DIGITAL_PRODUCT_ACCESS, RESEND_SHIPPING } from "../../../Api";
import { AppContext } from "../../../context/AppContext";
import { currencyFormatter } from "../../../util/Currencyhelpers.js";
import getRandomInt from "../../../util/randomID";
import AccessProductTooltip from "./AccessProductTooltip";
import RefundAuthFailure from "./RefundAuthFailure";
import RefundOrCancelState from "./RefundOrCancelState";
import ShippingStatus from "./ShippingStatus.js";

const breakpoints = DSBreakpoints.default;
const { cbNeutral, palette } = DSPalette;

const styles = {
	root: {
		"& h3.MuiTypography-root, & h5.MuiTypography-root": {
			color: cbNeutral[400]
		}
	},
	buttonColumn: {
		paddingBottom: "1rem",

		"& .MuiButton-root": {
			marginTop: "1rem",
			whiteSpace: "normal"
		}
	},
	imageContainer: {
		[breakpoints.up("md")]: {
			marginBottom: "10px",
			padding: 0
		},
		"& img, & svg": {
			maxWidth: 160,
			height: "auto",
			paddingRight: "1rem"
		}
	},
	refundAuthFailure: {
		color: palette.error.dark,
		fontSize: "14px",
		fontWeight: 400
	}
};

const NonRecurringItem = ({
	email,
	lineItem,
	currencyCode,
	refundEligibility,
	refundExpDate,
	refundExpired,
	isRefundOrCancelState,
	isRefundAuthFailState,
	isResubmitRefundAllowed,
	refundAuthDate,
	receiptNo,
	isProductAccessible,
	isRefundEligible,
	isRefundRequestable,
	setTicketModalOpen,
	setTicketType,
	hasTickets,
	isResendShippingDetail,
	setInstructionsModalOpen
}) => {
	const options = { year: "numeric", month: "long", day: "numeric" };
	const { setAlertText, showAlert, setAlert, setActionSuccessful } = useContext(AppContext);

	// Safari appears to block asynchronous calls to `window.open`.
	// Create the window synchronously and then update the location asynchronously
	// https://stackoverflow.com/questions/20696041/window-openurl-blank-not-working-on-imac-safari/70463940#70463940
	const newWindow = useRef(null);
	const [getAccessProductLink, { loading: linkLoading }] = useLazyQuery(DIGITAL_PRODUCT_ACCESS, {
		variables: { receipt: receiptNo, lineItemId: parseInt(lineItem.id) },
		onCompleted: data => {
			if (data?.digitalProductAccessLink && newWindow.current) {
				newWindow.current.location = data?.digitalProductAccessLink;
			} else {
				newWindow.current?.close();
			}
		}
	});

	const message = {
		SUCCESS: (
			<Trans i18nKey="OrderSummary.shippingSuccessMessage">
				An email containing your shipping details was sent to the following email address:{" "}
				{{ email }}
			</Trans>
		),
		FAILED: (
			<Trans i18nKey="OrderSummary.shippingFailedMessage">
				An error has occurred. An email containing your shipping details could not be sent
				to the following email address: {{ email }}
			</Trans>
		)
	};

	const handleShippingError = () => {
		!showAlert && setAlert(true);
		setAlertText(message.FAILED);
		setActionSuccessful(false);
	};

	const handleShippingSuccess = () => {
		!showAlert && setAlert(true);
		setAlertText(message.SUCCESS);
		setActionSuccessful(true);
	};

	const [resendShippingAction, { loading: shippingLoading }] = useMutation(RESEND_SHIPPING, {
		variables: {
			receipt: receiptNo,
			lineItemId: parseInt(lineItem.id, 10),
			language: getI18n()?.language
		},
		client: ApolloClient,
		onError: handleShippingError,
		onCompleted: handleShippingSuccess
	});

	const formattedRefundExpDate = refundExpDate?.toLocaleDateString(
		`${getI18n()?.language}-US`,
		options
	);

	const hasShipped = !!lineItem?.shippingNotice;
	const instructions = lineItem.product.vendor.physicalReturnInstructions;

	return (
		<>
			<Grid container direction="row" spacing={{ xs: 0, md: 8 }}>
				<Grid item xs={12} md={6} lg={7}>
					<Stack direction="row" pb={2}>
						{lineItem.product.imageUrl && (
							<Box sx={styles.imageContainer}>
								<img
									src={lineItem.product.imageUrl}
									alt={lineItem.product.imageAltText}
								/>
							</Box>
						)}

						<Box>
							<Typography variant="h5" fontWeight={600} as="h3">
								{lineItem.title}
							</Typography>

							{lineItem.product.shippable && <ShippingStatus shipped={hasShipped} />}
						</Box>
					</Stack>

					{lineItem?.shippingNotice?.trackingNumber && (
						<Stack direction="row" pb={1}>
							<Typography variant="body2" sx={{ wordBreak: "break-word" }}>
								{lineItem.shippingNotice?.carrier}{" "}
								<Trans i18nKey="Common.shipping.trackingNumber">
									Tracking Number:
								</Trans>
								&nbsp;&nbsp;
								{lineItem.shippingNotice?.trackingUrl ? (
									<Link
										sx={{ display: "inline-block" }}
										href={lineItem?.shippingNotice?.trackingUrl}
										target="_blank"
										underline="always"
									>
										{lineItem?.shippingNotice?.trackingNumber}
									</Link>
								) : (
									lineItem?.shippingNotice?.trackingNumber
								)}
							</Typography>
						</Stack>
					)}

					<Stack spacing={{ xs: 1, sm: 3 }}>
						<Typography variant="h5" fontWeight={700} as="p">
							{currencyFormatter(lineItem.price, currencyCode)}
						</Typography>
						{lineItem.quantity && lineItem.quantity > 1 && (
							<Typography variant="body2" fontWeight={400}>
								<Trans i18nKey="ProductDescription.quantity">Quantity:</Trans>{" "}
								{lineItem.quantity}
							</Typography>
						)}
					</Stack>

					{refundEligibility() !== "REFUNDED" && (
						<Typography variant="caption" fontWeight={400} sx={{ my: 1 }}>
							{refundEligibility() === "REFUND_ELIGIBLE_SHIPPED" && (
								<Trans i18nKey="OrderDetails.refundShippedText">
									Product has shipped, so please contact the vendor directly for
									assistance.
								</Trans>
							)}
							{refundEligibility() === "REFUND_ELIGIBLE" && !refundExpired && (
								<>
									<Trans i18nKey="OrderDetails.refundEligibleText">
										Refund eligible through
									</Trans>{" "}
									<strong>{formattedRefundExpDate}</strong>.
								</>
							)}
							{refundEligibility() === "REFUND_NOT_ELIGIBLE" && !refundExpDate && (
								<>
									<Trans i18nKey="OrderDetails.refundNotEligible">
										Not eligible for a refund.
									</Trans>
								</>
							)}
							{refundEligibility() === "REFUND_NOT_ELIGIBLE" && !!refundExpired && (
								<>
									<Trans i18nKey="OrderDetails.refundNotEligibleAndExpired">
										Not eligible for a refund. Refund window expired
									</Trans>{" "}
									<strong>{formattedRefundExpDate}</strong>.
								</>
							)}
						</Typography>
					)}
				</Grid>

				<Grid item xs={12} md={6} lg={5} sx={styles.buttonColumn}>
					{isRefundOrCancelState() && (
						<RefundOrCancelState
							lineItem={lineItem}
							isRefundAuthFailState={isRefundAuthFailState}
							isResubmitRefundAllowed={isResubmitRefundAllowed}
							refundAuthDate={refundAuthDate}
						/>
					)}

					{(isRefundAuthFailState || isResubmitRefundAllowed) && (
						<RefundAuthFailure
							isResubmitRefundAllowed={isResubmitRefundAllowed}
							setTicketModalOpen={setTicketModalOpen}
							setTicketType={setTicketType}
						/>
					)}

					{isProductAccessible() && (
						<>
							<LoadingButton
								fullWidth
								id={"accessProductButton" + getRandomInt()}
								color="primary"
								onClick={() => {
									newWindow.current = window.open();
									getAccessProductLink();
								}}
								disabled={linkLoading}
								loading={linkLoading}
							>
								<Trans i18nKey="OrderDetails.accessproductButton">
									Access This Product
								</Trans>
							</LoadingButton>

							<AccessProductTooltip />
						</>
					)}
					{isResendShippingDetail && (
						<LoadingButton
							fullWidth
							id={"resendEmail" + getRandomInt()}
							color="secondary"
							onClick={() => resendShippingAction()}
							disabled={showAlert || shippingLoading}
							loading={shippingLoading}
						>
							<Trans i18nKey="OrderDetails.resendShippingDetailsButton">
								Resend Shipping Details Email
							</Trans>
						</LoadingButton>
					)}
					{!hasTickets && (
						<>
							<Button
								fullWidth
								variant="outlined"
								color="primary"
								id={"productSupportButton" + getRandomInt()}
								onClick={() => {
									setTicketType("techSupport");
									setTicketModalOpen(true);
								}}
							>
								<Trans i18nKey="OrderDetails.button.creatSupportTicket">
									Create Support Ticket
								</Trans>
							</Button>
							{isRefundRequestable && !hasShipped && (
								<Button
									fullWidth
									variant="outlined"
									color="warning"
									id={"reqRefundButton2" + getRandomInt()}
									onClick={() => {
										setTicketType("requestRefund");
										setTicketModalOpen(true);
									}}
								>
									<Trans i18nKey="OrderDetails.button.requestRefund">
										Request Refund
									</Trans>
								</Button>
							)}
						</>
					)}
					{isRefundEligible && hasShipped && instructions && (
						<Button
							fullWidth
							color="secondary"
							id={"reviewReturnInstructionsButton" + getRandomInt()}
							onClick={() => {
								setInstructionsModalOpen(true);
							}}
						>
							<Trans i18nKey="OrderDetails.button.reviewReturnInstructions">
								Review Return Instructions
							</Trans>
						</Button>
					)}
				</Grid>
			</Grid>
		</>
	);
};

NonRecurringItem.propTypes = {
	currencyCode: PropTypes.string,
	refundEligibility: PropTypes.func,
	refundExpDate: PropTypes.instanceOf(Date),
	refundExpired: PropTypes.bool,
	isRefundOrCancelState: PropTypes.func,
	isRefundAuthFailState: PropTypes.bool,
	isResubmitRefundAllowed: PropTypes.bool,
	refundAuthDate: PropTypes.string,
	receiptNo: PropTypes.string,
	isProductAccessible: PropTypes.func,
	isRefundEligible: PropTypes.bool,
	isRefundRequestable: PropTypes.bool,
	setTicketModalOpen: PropTypes.func,
	setTicketType: PropTypes.func,
	hasTickets: PropTypes.bool,
	isResendShippingDetail: PropTypes.bool,
	setInstructionsModalOpen: PropTypes.func
};

export default NonRecurringItem;
